.full-screen-bg {
    background-color: white;
}

.rcw-launcher {
    background-color: #137ea7 !important; 
    color: white; 
    border: none; 
}

.rcw-title {
    background-color: #137ea7 !important; 
}

.rcw-header {
    background-color: #137ea7 !important; 
}

.rcw-client .rcw-message-text {
    background-color: rgba(19, 126, 167, 0.15) !important; /* 20% opacity */
}


.chat-image-container img {
    object-fit: contain;
    width: 100%;
    height: 200px;
}

.custom-tooltip {
    border: 1px solid grey
}

.settings-popover .popover-arrow {
    display: none;
}

.settings-popover {
  border: 1px solid black;
}

.custom-white-button {
  background-color: white !important; /* Set background to white */
  color: black !important;            /* Text color can be set to black */
  border: 1px solid black !important;            /* Remove border */
  box-shadow: none !important;        /* Remove any box shadows, if any */
  text-transform: none;
  font-weight: bold;
  display: flex;                      /* Use flexbox for layout */
  justify-content: space-between;     /* Space between items */
  align-items: center;                /* Center align items */
  max-width: 100%;                    /* Allow for max width */
}

.custom-white-button:hover {
  background-color: #e6e6e6 !important; /* Optional: light grey on hover */
  color: black !important;               /* Ensure text color remains black on hover */
  border: none;                          /* Keep border none on hover */
}


.att-search-title {
    margin-left: 15px;
}

.small-button {
  padding: 0.2rem 0.5rem;
  font-size: 0.7rem;
  height: 20px;
  line-height: 1;
}

.container-simple-title {
    margin-left: 15px;
}

.margin-map-search-result {
    margin-left: 10px;
}

.scrollable-column {
    overflow-y: auto;
    max-height: 100vh;
}

.do-not-move {
  position: fixed;
}

/* container-bckg { */
/*     background-color: rgba(175, 205, 215, 0.1); */
/* } */

.small-button-header {
    margin-right: 5px;
}

.graph-header {
    /* background-color: rgba(175, 205, 215, 0.30); */
    background-color: rgba(217, 225, 226, 0.30);
    padding: 2px;
    border-radius: 10px 10px 0px 0px;
    height: 30px;
}


.graph-bckg {
    /* background-color: rgba(175, 205, 215, 0.15); */
    background-color: rgba(217, 225, 226, 0.15);
    padding: 20px;
    border-radius: 0px 0px 10px 10px;
}

/* Large screen */
/* https://react-bootstrap.netlify.app/docs/layout/breakpoints/ */
@media all and (min-width: 992px) {
    .container-search-extended {
	margin-left: 15px;
    }

    .container-search {
	margin-top: 130px;
	margin-left: 15px;
    }

    .container-att-search {
	margin-top: 120px;
	margin-left: 15px;
    }

    .settings-popover {
	min-width: 400px; 
	max-width: 500px; 
    }

    .facet-tab-result {
	margin-top: 8px;
    }

    .tab-map-result {
	margin-top: 185px;
	margin-left: 10px;
    }

    .container-simple {
	margin-top: 125px;
	margin-left: 15px;
    }

    .footer-space {
	height: 185px;
    }

    .data-table-custom {
	padding-top: 30px;
    }

}

/* Medium screen */
@media all and (max-width: 992px) {
    .container-search {
	margin-top: 130px;
	margin-left: 15px;
    }

    .container-att-search {
	margin-top: 120px;
	margin-left: 15px;
    }

    .facet-tab-result {
	margin-top: 20px;
    }

    .tab-map-result {
	margin-top: 225px;
	margin-left: 10px;
    }

    .container-simple {
	margin-top: 135px;
    }

    .footer-space {
	height: 485px;
    }

}

/* Small screen */
@media all and (max-width: 768px) {
    .container-search {
	margin-top: 120px;
	margin-left: 15px;
    }

    .container-att-search {
	margin-top: 120px;
	margin-left: 15px;
    }

    .facet-tab-result {
	margin-top: 20px;
    }

    .tab-map-result {
	margin-top: 235px;
	margin-left: 10px;
    }

    .container-simple {
	margin-top: 155px;
    }

    .footer-space {
	height: 525px;
    }

}

@media all and (max-width: 576px) {
    .container-search {
	margin-top: 130px;
	margin-left: 15px;
    }

    .container-att-search {
	margin-top: 120px;
	margin-left: 15px;
    }

    .facet-tab-result {
	margin-top: 20px;
    }

    .tab-map-result {
	margin-top: 245px;
	margin-left: 10px;
    }

    .container-simple {
	margin-top: 155px;
    }

    .footer-space {
	height: 525px;
    }

}

.leaflet-container {
    height: 700px;
}

.map-pulldown {
    padding: 20px;
}

.map-neighbors {
    max-width: 360px;
    padding-left: 20px;
}

.tab-list {
    border-bottom: 1px solid #ccc;
}
.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    margin-left: 15px;
}

.tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    border-bottom: white;
}

.bg-light {
    padding: 10px;
    margin-top: 10px;
}

.bg-hard {
    padding: 30px;
    margin-top: 30px;
}


.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
      width: 100%;
  }

.debug {
    font-family: monospace, monospace;
    padding: 20px;
    margin-bottom: 10px;
}

/* for pagination and footer */
.center-row {
    display: flex;
    justify-content: center;
    align-items: center;
}


ul {
    margin-top: 20px;
    margin: 5px 0;
    line-height: 18px;
}


.link-button {
    color: #158cba;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    text-align: left;
}

.auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

.auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

.auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
}

.auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}

.auth-label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

code {
    font-family: Consolas, "courier new";
    color: crimson;
    background-color: #f1f1f1;
    padding: 2px;
    font-size: 100%;
    white-space: pre;
}


table {
    margin-top: 5px;
}

td {
    padding: 5px;
}

tr:not(:last-child) {
    margin-bottom: 10px;
}

/* tbody tr:nth-child(odd) { */
/*   background-color: lightgray; */
/* } */

/* tbody tr:nth-child(even) { */
/*   background-color: silver; */
/* } */

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}


.tab-icon-arxiv {
    width: 48px;
    height: 20px;
    margin-right: 5px;
}

.tab-icon-chemrxiv {
    width: 95px;
    height: 20px;
    margin-right: 5px;
}

.tab-icon-biorxiv {
    width: 73px;
    height: 20px;
    margin-right: 5px;
}

.tab-icon-medrxiv {
    width: 87px;
    height: 20px;
    margin-right: 5px;
}

.tab-content {
    margin-top: 10px;
    margin-right: 15px;
}
